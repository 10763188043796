import {Button, Col, Form, Input, Modal, notification, Row, Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import TextArea from "antd/lib/input/TextArea";
import EmployeeSelect from "../Employees/EmployeeSelect";
import ContactSelect from "../Contacts/ContactSelect";

const gutter = [8, 0];
const communication_cases = ['phone', 'email'];
const communications = ["review", "verification", "finance", "operational", "other"];

/**
 * @component
 * @alias BuildingAddCommunication
 * @prop {function} addCommunication - function that sends post request to api
 */

const BuildingAddCommunication = ({ id, onUpdate }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  const [form] = Form.useForm();

  const addCommunication = (values) => {
    values.id = parseInt(id)
    coreApi
      .post("/emails/create/new", values)
      .then((response) => {
        onUpdate();
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      }).finally(() => {

    });
  };

  return (
    <React.Fragment>
      <Button type="primary" onClick={() => { setModalOpen(true);}}>
        {t("emails.communication_add")}
      </Button>
      <Modal
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={() => {
          form.submit();
        }}
        okText={t("common.add")}
        open={isModalOpen}
        title={t("emails.communication_add_title")}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            await addCommunication(values);
            form.resetFields();
            setModalOpen(false);
          }}
          layout={"horizontal"}
        >
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item
                name={"case"}
                label={t("emails.communication")}
                rules={[{required: true}]}
                initialValue={'phone'}
              >
                <Select>
                  {communication_cases.map((type) => (
                    <Select.Option value={type} key={type}>
                      {t("emails.communication_cases." + type)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={"type"} label={t("emails.communication_type")}>
                <Select
                  allowClear
                >
                  {communications.map((type) => (
                    <Select.Option key={type} value={type}>
                      {t("emails.types." + type)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <ContactSelect
                id={id}
                label={t("emails.with")}
              />
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item name={"subject"} label={t("emails.description")}>
                <TextArea></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={24}>
              <EmployeeSelect
                required={true}
                name={"from"}
                label={t("common.user")}
                activeUsers={true}
                placeholder={''}
                initialValue={JSON.parse(localStorage.getItem("user")).id}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BuildingAddCommunication;
